<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/Revenue/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="update_date" class="col-sm-2 col-form-label">出表日期</label>
							<div class="col-sm-10">
								<input v-model="model.update_date"
									type="date"
									class="form-control"
									id="update_date"
									name="update_date"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="yymm" class="col-sm-2 col-form-label">資料年月</label>
							<div class="col-sm-10">
								<input v-model="model.yymm"
									type="text"
									class="form-control"
									id="yymm"
									name="yymm"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="code" class="col-sm-2 col-form-label">公司代號</label>
							<div class="col-sm-10">
								<input v-model="model.code"
									type="text"
									class="form-control"
									id="code"
									name="code"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="stock_name" class="col-sm-2 col-form-label">公司名稱</label>
							<div class="col-sm-10">
								<input v-model="model.stock_name"
									type="text"
									class="form-control"
									id="stock_name"
									name="stock_name"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="class_id" class="col-sm-2 col-form-label">產業別</label>
							<div class="col-sm-10">
								<input v-model="model.class_id"
									type="text"
									class="form-control"
									id="class_id"
									name="class_id"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="revenue" class="col-sm-2 col-form-label">當月營收</label>
							<div class="col-sm-10">
								<input v-model="model.revenue"
									type="text"
									class="form-control"
									id="revenue"
									name="revenue"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="revenue_last_month" class="col-sm-2 col-form-label">上月營收</label>
							<div class="col-sm-10">
								<input v-model="model.revenue_last_month"
									type="text"
									class="form-control"
									id="revenue_last_month"
									name="revenue_last_month"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="revenue_last_year" class="col-sm-2 col-form-label">去年當月營收</label>
							<div class="col-sm-10">
								<input v-model="model.revenue_last_year"
									type="text"
									class="form-control"
									id="revenue_last_year"
									name="revenue_last_year"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="revenue_change_month" class="col-sm-2 col-form-label">上月比較增減(%)</label>
							<div class="col-sm-10">
								<input v-model="model.revenue_change_month"
									type="text"
									class="form-control"
									id="revenue_change_month"
									name="revenue_change_month"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="revenue_change_year" class="col-sm-2 col-form-label">去年同月增減(%)</label>
							<div class="col-sm-10">
								<input v-model="model.revenue_change_year"
									type="text"
									class="form-control"
									id="revenue_change_year"
									name="revenue_change_year"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="accumulation" class="col-sm-2 col-form-label">當月累計營收</label>
							<div class="col-sm-10">
								<input v-model="model.accumulation"
									type="text"
									class="form-control"
									id="accumulation"
									name="accumulation"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="accumulation_last_year" class="col-sm-2 col-form-label">去年累計營收</label>
							<div class="col-sm-10">
								<input v-model="model.accumulation_last_year"
									type="text"
									class="form-control"
									id="accumulation_last_year"
									name="accumulation_last_year"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="accumulation_change_month" class="col-sm-2 col-form-label">累計營收前期比較增減(%)</label>
							<div class="col-sm-10">
								<input v-model="model.accumulation_change_month"
									type="text"
									class="form-control"
									id="accumulation_change_month"
									name="accumulation_change_month"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note"
									type="text"
									class="form-control"
									id="note"
									name="note"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue'
	import { addRevenue } from '@/api/Revenue.js'

	export default {
		name: 'ProjectCreate',
		components: {
			LayoutDiv
		},
		data() {
			return {
				model: {
					update_date: '',
					yymm: '',
					code: '',
					stock_name: '',
					class_id: '',
					revenue: '',
					revenue_last_month: '',
					revenue_last_year: '',
					revenue_change_month: '',
					revenue_change_year: '',
					accumulation: '',
					accumulation_last_year: '',
					accumulation_change_month: '',
					note: '',

				},
				isSaving: false,
			};
		},
		methods: {
			handleSave() {
				this.isSaving = true

				addRevenue(this.model).then(rep => {

					this.isSaving = false;
					this.reset();
					return rep;
				})
				.catch(error => {
					this.isSaving = false
					return error
				});
			},
			reset() {
					this.model.update_date = "";
					this.model.yymm = "";
					this.model.code = "";
					this.model.stock_name = "";
					this.model.class_id = "";
					this.model.revenue = "";
					this.model.revenue_last_month = "";
					this.model.revenue_last_year = "";
					this.model.revenue_change_month = "";
					this.model.revenue_change_year = "";
					this.model.accumulation = "";
					this.model.accumulation_last_year = "";
					this.model.accumulation_change_month = "";
					this.model.note = "";

			}
		}
	};
</script>
